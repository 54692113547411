'use strict';

const _ = require('lodash')

module.exports = {

  getGroupDisplay: function(eventDiscipline, discipline) {
    if (eventDiscipline.groupDisplay) return eventDiscipline.groupDisplay
    if (discipline.labels.groupDisplay) return discipline.labels.groupDisplay
    return 'members'
  },

  getTeamDisplay: function(eventDiscipline) {
    if (eventDiscipline.teamDisplay) return eventDiscipline.teamDisplay
    return 'team'
  },

  getPersonNames: function(participant, type, groupDisplay = 'members') {
    // console.log(participant, type, groupDisplay)
    switch (type) {
      case 'clubMember':
        return [this.getMemberName(participant)]
      case 'group': {
        if (groupDisplay === 'group') {
          return [this.getName(participant, type)]
        }
        return _.map(participant.members, m => this.getMemberName(m.member))
      }
      case 'team': {
        return _.map(participant.members, m => this.getMemberName(m.member))
      }
    }
  },

  getName: function(participant, type) {
    if (type === 'clubMember') {
      return this.getMemberName(participant)
    }
    else {
      return _.toUpper(_.trim(participant.name))
    }
  },

  getMemberName: function(member) {
    if (! member) {
      return 'missing'
    }
    return _.toUpper(_.trim(member.lastName)) + ' ' +
      _.trim(member.firstname).replace(/[^-'\s]+/g, word =>
        word.replace(/^./, first => first.toUpperCase()))
  },

  getStatus: function (participation, roundIndex) {
    const partRound = participation.rounds.find(r => r.roundIndex === roundIndex)

    if (! partRound) return null

    return partRound.status
  },
}
